<template>
  <div>
    <TopParallax
      title="REASON FOR THE CREATION OF THE FOUNDATION"
      :img-src="imgSrc"
    />
    <v-card rounded="0" color="grey darken-3">
      <v-tabs right background-color="grey darken-3" color="white">
        <v-tab style="color:white">
          The Founder
        </v-tab>
        <v-tab style="color:white">
          Reason for Foundation
        </v-tab>

        <v-tab-item>
          <v-card flat rounded="0">
            <v-card-text>
              <v-container class="py-8">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-img src="@/assets/images/Photo of our Story Founder.jpg" contain max-height="350" />
                    <h5 class="h5 my-3 text-center">Esperança Baião Alfico
                      <br> Founder, Hope and Angels Foundation</h5>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <h4 class="h2 pb-3">The Founder</h4>
                    <h5 class="h5 my-3">I was born and raised in Angola - Southern Africa. I am a woman who carries the deep wounds of a childhood marked by poverty. I remember several sad times in my life when I slept on the ground in the open, without hope, unsure of tomorrow, hungry with not even a glass of water available to me to fool the hunger.</h5>
                    <h5 class="h5 my-3">For years, living abused by this dark shadow, I moved from one Angolan province to another. I was in search of a place where I would be accepted and truly loved; somewhere where I could leave my past behind me. At the right time, God brought a wonderful man into my life with whom I formed a family. In 2001, God gave us the privilege of coming to Canada as Refugees. In this wonderful country, I found "milk and honey", that is, a quality life where the human person is valued, well cared for and protected. There are those who say, "Canada is no place to live because of the winters,” but it was here that I found the fulfillment of my biggest dreams.</h5>
                  </v-col>
                </v-row>
                <h5 class="h5 my-3">In many parts of the world, particularly in developing countries in Africa, there are thousands of children who have experienced greater distresses in their lives than I have experienced. But having lived through some of the same painful circumstances of many of them, I can personally relate to the pangs of anguish in their lives. Moved by the great humanitarian spirit that exists in this country, Canada, and my deep compassion for impoverished children living in Angola, a DREAM was born in me! The DESIRE and the MORAL COMMITMENT to do everything in my power to reduce the suffering of these children!</h5>
                <h5 class="h5 my-3">This is the dream of a former street girl screaming to the world for HELP for thousands of children who are in the hopeless streets of tomorrow. In order to realize this DREAM and the commitment I established with myself, with God and with these children, I would like to introduce you to HOPE AND ANGELS FOUNDATION.</h5>
                <v-divider />
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat rounded="0">
            <v-card-text>
              <FoundationContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import AboutBar from '@/components/hope/AboutBar.vue'
import FoundationContent from '@/components/hope/FoundationContent.vue'

export default {
  components: {
    TopParallax,
    FoundationContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide1.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Story'
      }
    }
  }
}
</script>
